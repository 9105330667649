import React from "react";
import { configFile } from "../../Config/Constant";
import { Link } from "react-router-dom";

function Info()
{
        return (
            <section className="info-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <img src={configFile.Web_Hosting_Solutions} alt="web hosting solution" className="img-fluid" />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="content">
                                <img src={configFile.power_icon} alt="power button" className="img-fluid"/>
                            <h3 className="title">Growing your business all start from somewhere.</h3>
                            <p className="text">Let people know more about your brand and your products. Start your online store today to grow your visibility</p>
                            <h3 className="title">Let us be the right hosting provider for your business.</h3>
                            <p className="text">Choosing the right hosting provider is as choosing the best location for your business</p>
                            <h3 className="title">Make your idea a reality.</h3>
                            <p className="text">Make a tiny step to fulfill your dream by owning a domain and starting from somewhere.</p>
                      </div>
                      <div className="action-wrapper">
                            <Link href="#" className="link">get started</Link>
                      </div>
                        </div>
                    </div>
                </div>
            </section>
        );
}

export default Info