import React from "react";
import {configFile} from "../../Config/Constant";
import { Link } from "react-router-dom";

function Slider()
{
    return (
        <section id="slider">
            <div className="slider-wrapper">
                <div className="animation">
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                </div>
                <div className="inner-content">
                    <div className="item">
                       <div className="container">
                       <div className="row justify-content-center">
                       <div className="col-sm-12 col-md-6 col-lg-6">
                            <h3 className="title">Top-Rated Web Hosting Solutions for Fast and Reliable Performance</h3>
                            <p className="text">Unlock Your Online Potential with Our Lightning-Fast Web Hosting, Backed by 99.9% Uptime and 24/7 Expert Support!</p>
                        <div className="button-group">
                            <Link className="btn action-btn" to={'/cpanel-hosting/cpanel-hosting'}>get started</Link>
                            <Link className="btn action-btn" to={'/wordpress-hosting/wordpress-hosting'}>learn more</Link>
                        </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <img src={configFile.hosting_banner1}  alt="hosting banner" className="img-fluid"/>
                        </div>
                       </div>
                       </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Slider;