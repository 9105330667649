import react from 'react-dom';
import { Link } from 'react-router-dom';
import {act, useEffect, useState} from 'react';
import { configFile } from '../../Config/Constant';

function Navbar()
{
    const [active, setActive] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(false);
    
    const toggleMenu = () => {
        setActive(!active);
    };

    const toggleDropdownMenu = (dropdownID) => {
        setActiveDropdown(activeDropdown === dropdownID ? false : dropdownID);
    };

    const [ApiError, setApiError] = useState(null);
    const [productCategories, setProductCategories] = useState([]);

    useEffect(() => {
        document.addEventListener("click", (event) =>{
           var dropdownsElement = document.querySelectorAll(".item.menu-dropdown");
        if(dropdownsElement.length > 0)
        {
            let DropdownClicked = false;
            dropdownsElement.forEach((dropdown) =>{
                if(dropdown.contains(event.target))
                {
                    DropdownClicked = true;
                }
            });

            if(!DropdownClicked)
            {
                dropdownsElement.forEach((dropdown) =>{
                    if(dropdown.classList.contains("active"))
                    {
                        dropdown.classList.remove("active");
                    }
                });
            }
        }

        });

        const fetchCategory = async (event) =>{
          try{
              let Api_URL = process.env.REACT_APP_API_URL+'/api/products/category';
              let response = await fetch(Api_URL,
                 {
                     method: "GET",
                     header :{
                             "content-type" : "application/json",
                             "Accept" : "application/json",
                             "Cache-Control" : "no-cache"
                         }
              });

              if(!response.ok)
              {
                setApiError("Unable to connect");
              }

              let result = await response.json();
              if(result.status === 200)
              {
                    setProductCategories(result.data);
              }

          }catch(e)
          {
              setApiError(e.message);
          }
        }
        fetchCategory();

        }, []);

    return(
            <div className="navbar" id="header-middle">
            <div className="container">
                <div className="header-content-wrapper">
                    <div className="logo">
                <a href="/">
                <img src={configFile.logo} alt="logo" className="img-fluid" />
                </a>
                    <div className="toggler-wrapper">
                        <button className="btn toggle-header-menu" onClick={toggleMenu}><i className="fa-solid fa-bars"></i></button>
                    </div>
                </div>
                    <div className={ active ? 'active header-right-content' : 'header-right-content'}>
                        <ul className="menu-list">
                            <li className="item">
                            <a href="/" className="link">Home</a>
                        </li>
                        <li className="item">
                            <a href="/domains" className="link">Domains</a>
                        </li>
                            {
                                Object.values(productCategories) ? (
                                    Object.values(productCategories).map(category => (
                                       <>
                                           <li className={activeDropdown === category.category_name ? 'item menu-dropdown active' : 'item menu-dropdown'}>
                                               <button className="btn menu-dropdown-btn link"
                                                       onClick={() => toggleDropdownMenu(category.category_name)}>
                                                   <span className="text">{category.category_name}</span>
                                               </button>
                                               <div className="menu-dropdown-widget">
                                                   {
                                                       category.products.length > 0 ? (
                                                           category.products.map((product_category) =>
                                                               product_category.status === "active" ? (
                                                                   <a href={`/${product_category.module}-hosting/${product_category.slug}`} className="menu-link" key={product_category.id}>
                                                                       <div className="widget-item">
                                                                           <div className="item-icon">
                                                                               <img src={process.env.REACT_APP_API_URL+'/'+product_category.icon} alt="cPanel Hosting" className="img-fluid" />
                                                                           </div>
                                                                           <div className="text-wrap">
                                                                               <p className="text">{product_category.name}</p>
                                                                               <small>{product_category.headline}</small>
                                                                           </div>
                                                                       </div>
                                                                   </a>
                                                               ) : null
                                                           )
                                                           ) : null
                                                   }
                                               </div>
                                           </li>
                                       </>
                                   ))
                                ) : null
                            }
                            <li className={activeDropdown === 'website' ? 'item menu-dropdown active' : 'item menu-dropdown'}>
                                <button className="btn menu-dropdown-btn link"
                                        onClick={() => toggleDropdownMenu('website')}>
                                    <span className="text">Website Builder</span>
                                </button>
                                <div className="menu-dropdown-widget single-dropdown">
                                    <a href="https://bigeweb.com/billing/index.php?rp=/store/website-builder" class="menu-link" target="_self">Weebly</a>
                                    <a href="https://bigeweb.com/billing/index.php?rp=/store/site-builder" class="menu-link" target="_self">Site Builder</a>
                                </div>
                            </li>
                            <li className="item">
                                <a href={'/contact'} className="link">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Navbar;