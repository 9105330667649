import React from "react";
import { configFile } from "../../Config/Constant";
import { Link } from "react-router-dom";

function Ads()
{

    const handleButtonClicked = (event) =>{
        event.preventDefault();
        var page = document.querySelector("#slider");
        if(page)
        {
            page.querySelector(".item").scrollIntoView({behavior : 'smooth'})
        }
    }
        return (
            <section className="domain-banner">
                <div className="container">
                    <div className="row g-3">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="title">transfer your domain to us.</h3>
                                </div>
                                <div className="card-body">
                                    <img src={configFile.domain_image} className="img-fluid" alt="domain"/>
                                    <p className="text">Get better renewal rates by transferring your domain to Bigeweb! We promise the best prices out there. Make the switch today!</p>
                                </div>
                                <div className="card-footer">
                                    <Link to="javascript:void(0)" className="action-link" onClick={handleButtonClicked}>buy now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="title">add web hosting from us.</h3>
                                </div>
                                <div className="card-body">
                                    <img src={configFile.big_data} className="img-fluid" alt="bigdata"/>
                                    <p className="text">Boost your online presence with Bigeweb's top-notch hosting services! We provide fast, secure, and user-friendly hosting solutions designed for businesses of all sizes.</p>
                                </div>
                                <div className="card-footer">
                                    <Link to="/hosting/cpanel-web-hosting" className="action-link">get started</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
}

export default Ads;