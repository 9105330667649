import React, { useState } from "react";
import { configFile } from "../../Config/Constant";

function DomainSelector()
{

    const [buttonValue, setbuttonValue] = useState('');

    const handleCartButton = (event) => {
        event.preventDefault();
        const value = event.target.value;
           const inputField = document.querySelector("input[name='domain']");
           inputField.value = value;
           inputField.focus();
           const parentDiv = inputField.closest(".item");
           if(parentDiv)
           {
            parentDiv.scrollIntoView({behavior : 'smooth'});
           }
    }


    return (
        <section className="domain-selector">
            <div className="container">
                <h5 className="title">choose from one of these domain extensions</h5>
                <div className="card-group">
                <div className="card-wrapper domain-wrapper-card">
                            <div className="card custom-card animate-content-from-left">
                                <div className="card-header">
                                    <img src={configFile.dotcom} alt="com" className="img-fluid" />
                                </div>
                                <div className="card-body">
                                    <p className="text">
                                        If you are looking to do business in more than one location, then .com provides you with a domain name that will work well in most markets.
                                    </p>
                                </div>
                                <div className="card-footer">
                                    <button className="btn add-to-cart btn" value=".com" onClick={handleCartButton}>buy now</button>
                                </div>
                            </div>
                            <div className="card custom-card animate-content-from-left">
                                <div className="card-header">
                                    <img src={configFile.dotedu} alt="edu" className="img-fluid" />
                                </div>
                                <div className="card-body">
                                    <p className="text">
                                        Some of the general benefits include: Free access to online educational courses. Free access to content and streaming tools.
                                    </p>
                                </div>
                                <div className="card-footer">
                                    <button className="btn add-to-cart btn" value=".edu" onClick={handleCartButton}>buy now</button>
                                </div>
                            </div>
                            <div className="card custom-card animate-content-from-left">
                                <div className="card-header">
                                    <img src={configFile.dotbiz} alt="biz" className="img-fluid" />
                                </div>
                                <div className="card-body">
                                    <p className="text">
                                        This extension keeps your website URL friendly and short. It also increases the chance of potential visitors to the website
                                    </p>
                                </div>
                                <div className="card-footer">
                                    <button className="btn add-to-cart btn" value=".biz" onClick={handleCartButton}>buy now</button>
                                </div>
                            </div>
                            <div className="card custom-card animate-content-from-left">
                                <div className="card-header">
                                    <img src={configFile.dotstore} alt="store" className="img-fluid" />
                                </div>
                                <div className="card-body">
                                    <p className="text">
                                        Your marketing budget may lean heavily toward strategies like search engine optimization (SEO) and social media marketing
                                    </p>
                                </div>
                                <div className="card-footer">
                                    <button className="btn add-to-cart btn" value=".store" onClick={handleCartButton}>buy now</button>
                                </div>
                            </div>
                            <div className="card custom-card animate-content-from-left">
                                <div className="card-header">
                                    <img src={configFile.dotmy} alt="my" className="img-fluid" />
                                </div>
                                <div className="card-body">
                                    <p className="text">
                                        High Search Ranking on the “Search Engine”
                                    </p>
                                </div>
                                <div className="card-footer">
                                    <button className="btn add-to-cart btn" value=".my" onClick={handleCartButton}>buy now</button>
                                </div>
                            </div>
                            <div className="card custom-card animate-content-from-left">
                                <div className="card-header">
                                    <img src={configFile.dotgov} alt="gov" className="img-fluid" />
                                </div>
                                <div className="card-body">
                                    <p className="text">
                                        This make it easy to know that government services are official.
                                    </p>
                                </div>
                                <div className="card-footer">
                                    <button className="btn add-to-cart btn" value=".gov" onClick={handleCartButton}>buy now</button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
    );
}

export default DomainSelector;